<template>
  <div>
    <el-cascader v-model="value" size="medium" :options="[]"></el-cascader>
    <el-input
      size="medium"
      style="margin-top: 10px"
      readonly
      placeholder="请输入详细地址"
      show-word-limit
      :rows="2"
      type="textarea"
    />
  </div>
</template>
<script>
export default {
  name: "City",
  data() {
    return {
      value: [],
    };
  },
};
</script>


